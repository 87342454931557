import React, { Component } from 'react'
import axios from 'axios'
import { LOADER, GETURL } from '../../utils/constants'

import './style.scss'

class Search extends Component{
  constructor(props){
    super(props)

    this.state = {
      query: '',
      results: {},
      loading: false,
    }

    this.cancel = ''
  }

  fetchSearchResults = (query) => {
    const searchUrl = `${GETURL}/debates?order=asc&orderby=date&title=${query}`

    if(this.cancel){
      this.cancel.cancel()
    }

    this.cancel = axios.CancelToken.source()

    axios.get(searchUrl, {
      cancelToken: this.cancel.token
    })
      .then(res => {
        const resultNotFoundMsg = !res.data.debates.length
          ? 'There are no more search results. Please try a new search'
          : ''
        this.setState({
          results: res.data.debates,
          message: resultNotFoundMsg,
          loading: false
        })
      })
      .catch((error) => {
        if(axios.isCancel(error) || error){
          this.setState({
            message:
              'Ocorreu um erro inesperado. Atualize sua página e tente novamente.',
          })
          console.log(error.message)
        }
      })
  }

  handleOnInputChange = (event) => {
    const query = event.target.value
    if(!query){
      this.setState({ query, results: {}, message: '' })
    } else{
      this.setState({ query, message: '' }, () => {
        if(query.length >= 3){
          this.fetchSearchResults(query)
          this.setState({ loading: true })
        }
      })
    }
  }

  renderSearchResults = () => {
    const { results } = this.state


    if(Object.keys(results).length && results.length){
      return (
        <div className="results-container">
          {results.map(result => {
            return (
              <a
                key={result.id}
                href={`single/${result._id}`}
                className="result-item"
              >
                {result.get_image && (
                  <div
                    className="image-wrapper"
                    style={{
                      backgroundImage: `url(${result.get_image})`,
                    }}
                  />
                )}
                <h6 className="image-username">
                  {result.title}
                </h6>
              </a>
            )
          })}

        </div>
      )
    }
  }


  render(){
    const { query, message, loading } = this.state

    return (
      <div className={`search-results`}>
        <label className="search-label" htmlFor="search-input">
          <input
            type="text"
            name="query"
            value={query}
            id="search-input"
            placeholder="Buscar debate..."
            onChange={this.handleOnInputChange}
          />
        </label>
        {loading && (
          <div className="results-container not-data">
            <div className="loadingSpinner"><img src={LOADER} alt="Carregando" /></div>
          </div>
        )}
        {message && (
          <div className="results-container not-data">
            <p className="message">{message}</p>
          </div>
        )}

        {/*	Result*/}
        {this.renderSearchResults()}
      </div>
    )
  }
}

export default Search
