import React, { Component } from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import { Container, Row, Col } from 'reactstrap'

import './style.scss'

import logo from '../../images/logo-talk-of-the-town.png'
import heroOne from '../../images/bg/hero_one.jpg'
import heroTwo from '../../images/bg/hero_two.png'

class Hero extends Component {
  componentWillMount() {
    const pictureArray = [heroOne, heroTwo]
    const randomIndex = Math.floor(Math.random() * pictureArray.length)
    const selectedPicture = pictureArray[randomIndex]

    this.setState({
      bgStyle: {
        backgroundImage: `url(${selectedPicture})`,
      },
    })
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={(data) => (
          <section className="hero" style={this.state.bgStyle}>
            <Container>
              <Row>
                <Col lg="6" className={`slogan ${!this.props.isHome ? 'inPage' : ''}`}>
                  <p>{this.props.text}</p>
                </Col>
                <Col lg="6" className="brand">
                  <Link to="/">
                    <img src={logo} alt={data.site.siteMetadata.title} />
                  </Link>
                </Col>
              </Row>
            </Container>
          </section>
        )}
      />
    )
  }
}

export default Hero
