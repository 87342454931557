import React, { useEffect, useState, useCallback } from 'react'
import { Container, Row, Col } from 'reactstrap'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import { POSTURL } from '../../utils/constants'

import './style.scss'

const Newsletter = () => {
  const [open, setOpen] = useState(false)

  const {
    handleSubmit, register, errors, formState,
  } = useForm({
    mode: 'onBlur',
  })

  const onSubmit = useCallback((data) => {
    axios
      .post(`${POSTURL}/newsletters`, data)
      .then()
      .catch()
  })

  useEffect(() => {
    const clickHandler = () => {
      setOpen(!open)
    }
    document.getElementById('openNews').addEventListener('click', clickHandler)
    return () => document.getElementById('openNews').removeEventListener('click', clickHandler)
  })

  /*
  const subscribeNews = useCallback(
    (data) => {
      axios
        .post(`${POSTURL}/newsletters`, data)
        .then(() => {
          setMessage(true)
        })
        .catch((error) => {
          if (error.response.status === 409) {
            alert('Cadastro já existe!');
          } else {
            alert('Erro inesperado.');
          }
        })
    },
  )
  */

  return (
    <section className="newsletter">
      <Container>
        <Row>
          <Col>
            <button
              aria-expanded={open === true ? 'true' : 'false'}
              className={`${open === true ? 'active' : ''} linkPages`}
              id="openNews"
            >
              <span>CADASTRE-SE PARA RECEBER A NOSSA NEWSLETTER</span>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                <path
                  fill="currentColor"
                  d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
                />
              </svg>
            </button>
            <div className={`${open ? 'open' : ''} newsletterForm`}>
              <Col>
                <p>
                  Assine nossa newsletter semanal para receber as últimas
                  notícias, atualizações e ofertas incríveis diretamente na sua
                  caixa de entrada.
                </p>
              </Col>
              <form className="row" onSubmit={handleSubmit(onSubmit)} noValidate>
                <Col lg="5">
                  <input
                    name="nome"
                    className="form-control col-12"
                    ref={register({ required: 'Digite seu nome' })}
                    placeholder="Digite seu nome"
                    disabled={formState.isSubmitting}
                  />
                  {errors.nome && <p className="error">{errors.nome.message}</p>}
                </Col>
                <Col lg="5">
                  <input
                    name="email"
                    type="email"
                    className="form-control"
                    placeholder="Digite seu email"
                    ref={register({
                      required: 'Digite seu email',
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: 'Insira um e-mail válido',
                      },
                    })}
                  />
                  {errors.email && <p className="error">{errors.email.message}</p>}
                </Col>
                <Col lg="2">
                  <button type="submit" className="button" disabled={formState.isSubmitting}>Enviar</button>
                </Col>
              </form>
              {formState.isSubmitSuccessful && (
                <p className="success">Cadastro realizado com sucesso!</p>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Newsletter
