import React, { useState } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import LoggedMenu from '../LoggedMenu'
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Container,
    Row,
    Col,
} from 'reactstrap'

import './style.scss'

const LoggedHeader = (props) => {
    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => setIsOpen(!isOpen)

    return (
        <header className={`header logged`}>
            <Container>
                <Row>
                    <Col lg="12">
                        <Navbar
                            color="light"
                            light
                            expand="md"
                            className={`loggedMenu`}
                        >
                            <NavbarBrand href="/">
                                <StaticQuery
                                    query={graphql`
                                        query {
                                            site {
                                                siteMetadata {
                                                    title
                                                }
                                            }
                                        }
                                    `}
                                    render={(data) => (
                                        <img
                                            src={require('../../images/logo-talk-of-the-town.svg')}
                                            alt={data.site.siteMetadata.title}
                                        />
                                    )}
                                />
                            </NavbarBrand>
                            <NavbarToggler onClick={toggle} />
                            <LoggedMenu />
                        </Navbar>
                    </Col>
                </Row>
            </Container>
        </header>
    )
}

export default LoggedHeader
