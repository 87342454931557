import React, { useState } from 'react'

import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  Container,
  Row,
} from 'reactstrap'
import { Link, StaticQuery, graphql } from 'gatsby'
import SearchBar from '../SearchBar'
import useAuth from '../../hooks/useAuth'

import logo from '../../images/logo-talk-of-the-town.png'

import './style.scss'
import LoggedHeader from "../LoggedHeader";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false)
  const { logout, isAuthenticated } = useAuth()

  const handleLogout = (e) => {
    e.preventDefault()
    logout()
  }

  const toggle = () => setIsOpen(!isOpen)
  return (
    <>
      <header>
        <Navbar color="transparent" dark expand="lg" className="menu">
          <StaticQuery
            query={graphql`
            query {
              site {
                siteMetadata {
                  title
                }
              }
            }
          `}
            render={(data) => (
              <NavbarBrand to="/" className="d-lg-none">
                <img src={logo} alt={data.site.siteMetadata.title} />
              </NavbarBrand>
            )}
          />
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Container>
              <Row>
                <Nav className="mr-auto" navbar>
                  <NavItem>
                    <Link to="/debates-in-english/">
                      Debates in English
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link to="/talks-and-courses/">Talks & Courses</Link>
                  </NavItem>
                  <NavItem>
                    <Link to="/debates-for-teachers/">
                      Debates for Teachers
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link to="/debates-for-schools/">
                      Debates for Schools
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link to="/talk-to-us/">Talk to us</Link>
                  </NavItem>
                </Nav>
                <SearchBar />
                {isAuthenticated ? (
                  <a onClick={handleLogout} className="Login" href="/">Logout</a>
                ) : (
                  <Link to="/login/" className="login">Login</Link>
                )}
              </Row>
            </Container>
          </Collapse>
        </Navbar>
      </header>
      {isAuthenticated && (
        <LoggedHeader />
      )}
    </>
  )
}
export default Header
