import React from 'react'
import { Nav, NavItem } from 'reactstrap'
import { Link } from 'gatsby'

import './style.scss'

const LoggedMenu = () => (
  <Nav className="ml-auto" navbar>
    <NavItem>
      <Link to="/app">Minhas Revisões</Link>
    </NavItem>
    <NavItem>
      <Link to="/app/my-debates/">Meus Debates</Link>
    </NavItem>
    <NavItem>
      <Link to="/app/my-account/">Minha conta</Link>
    </NavItem>
    <NavItem>
      <Link to="/app/invoices/">Pagamentos</Link>
    </NavItem>
  </Nav>
)

export default LoggedMenu
